.btn{
    display: inline-flex;
    padding: 0.625rem 1.75rem;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0.5rem;
    cursor: pointer;
}
.btn.disabled{
    pointer-events: none; /* Prevents clicks */
    opacity: 0.5; /* Makes the button look inactive */
    cursor: not-allowed; 
}
.bg-red{
    background-color: red !important;
}
.nav-container{
    width: 100%;
    height: 5.375rem;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-inner-container{
    /* width: 80%; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-inner-container-ar{
    flex-direction: row-reverse;
}
.nav-right-section{
    display: flex;
    gap: 3.13rem;
}
.nav-right-section-ar{
    flex-direction: row-reverse;
}
.login-reg-button-box{
    display: flex;
    gap: 1rem;
}
.tab-section-ar,
.login-reg-button-box-ar{
    flex-direction: row-reverse;
}

/* @media screen and (max-width:1800px){
    .nav-inner-container{
        width: 85%;
    }
} */
/* @media screen and (max-width:1400px){
    .nav-inner-container{
        width: 95%;
    }
} */
@media screen and (max-width:992px){
    .nav-inner-container{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    .nav-container{
        height: auto;
        align-items: flex-start;
        /* padding: 1rem 0.5rem; */
        padding: 1rem 2.06rem;
    }
    .nav-right-section{
        flex-direction: column;
        gap: 1rem;
    }
    .login-reg-button-box{
        flex-direction: column;
    }
}

@media screen and (max-width:576px){
    .nav-right-section{
        flex-direction: column;
    }
    .nav-inner-container{
        width: 100%;
    }
    .nav-container{
        height: auto;
        padding: 1.25rem 2rem;
        /* padding: 1.25rem; */
    }
}
    .checkbox-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        color:  #666;
        font-family: Poppins;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        gap: 0.63rem;
        width: fit-content;
    }
    
    .checkbox-container input {
        width: 1.3125rem;
        height: 1.3125rem;
        cursor: pointer;
        accent-color: #108AC6;
    }
    
    /* .checkmark {
        width: 18px;
        height: 18px;
        border: 2px solid #333;
        display: inline-block;
        margin-right: 10px;
        position: relative;
    }
    
    .checkmark::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 14px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        display: none;
    } */
    
    /* .checkbox-container input:checked + .checkmark {
        background-color: #007bff;
    }
    
    .checkbox-container input:checked + .checkmark::after {
        display: block;
    } */
.country-code{
    width: 40%;
}

.custom-select-container {
    position: relative;
    width: 40%;
    
}

.custom-select-box {
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    gap: 0.4rem;
}

.arrow {
    display: flex;
    transition: transform 0.3s ease-in-out;
    z-index: 100;
    background-color: inherit;
    height: 100%;
    align-items: center;
}

.custom-dropdown {
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    font-size: 1rem;
}

.custom-dropdown li {
    padding: 10px;
    cursor: pointer;
}

.custom-dropdown li:hover {
    background: #108AC6;
    color: white;
}
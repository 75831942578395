.register-pop-up{
    padding: 3.25rem 4.13rem;
    padding-bottom: 4.06rem;
    border-radius: 1.5rem;
    background: #FFF;
    width: 44rem;
    position: relative;
}
.register-head-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.62rem;
}
.gap-62{
    gap: 0.62rem !important;
}
.margin-new{
    margin-top: 0.7rem;
}
.error-text{
    color: red;
}

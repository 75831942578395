.main-nav-container{
    width: 100%;
    display: flex;
    justify-content: center;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);

}
.main-nav-inner-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5.375rem;
    align-items: center;
}
.nav-logo-img{
    height: 4.125rem;
    width: auto;
    cursor: pointer;
}
.nav-logo-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.image-tab-section{
    display: flex;
    gap: 5.03rem;
}
.tab-section{
    display:flex;
    align-items: center;
    gap: 3.31rem;
}
.notification-name-section{
    display: flex;
    align-items: center;
    gap: 3.25rem;
}
.name-icon-box{
    position: relative;
}
.other-profile-options{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 0.72rem;
    padding: 0.94rem;
    left: 0.3rem;
    top: 5rem;
    width: 12.25rem;
    height: 6rem;
    border-radius: 0.6875rem;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
}
.my-profile-options-box{
    display: flex;
    gap: 0.25rem;
    align-items: center;
    cursor: pointer;
    width: 100%;
}
.my-profile-options-box svg{
    display: flex;
}
.divider{
    height: 0.7px;
    border: 0.4px solid #B0B0B0;
    width: 100%;
}

.harmburger-icon{
    color: #acadaf;
    font-size: 25px;
    display: none;
    order: 3;
}


@media screen and (max-width:1200px){
    .main-nav-inner-container{
        width: 100%;
    }
    .main-nav-container{
        padding: 0 2.06rem;
    }
    .other-profile-options{
        left: -4.7rem;
        top: 4rem;
    }
}


@media screen and (max-width:992px){
    .image-tab-section{
        flex-direction: column;
        gap: 2.03rem;
        align-items: flex-start;
    }
    .main-nav-inner-container{
        align-items: flex-start;
    }
    .main-nav-inner-container{
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .tab-section{
        flex-direction: column;
        gap: 2.31rem;
        align-items: flex-start;
    }
    .name-icon-box .user-name-box{
        display: none;
    }
    .harmburger-icon{
        display: block;
    }
    .service-booking-right-section{
        padding-top: 1.19rem;
    }
    .other-profile-options {
        left: -10.7rem;
        top: 5.5rem;
    }
}

@media screen and (max-width:576px){
    .nav-logo-img{
        height: 2rem;
    }
    .harmburger-icon{
        font-size: 21px;
    }
}
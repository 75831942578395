.erro-message-box{
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 0.2rem;
    color: red;
}
.svg-erro-icon svg{
    display: flex;
    fill: var(--error-message);
}
.my-profile-main-container{
    display: flex;
    justify-content: center;
}
.my-profile-sub-container{
    margin-top: 2.44rem;
    /* width: 80%; */
    width: 73.5625rem;
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
    justify-content: start;
    text-align: start;
    padding-bottom: 9rem;
}
.my-profile-heading{
    color: #000;
    font-size: 2.0625rem;
    font-weight: 500;
    margin-top: 0.75rem;
    margin-bottom:0.75rem  ;  /* gap 1.12 +0.75 = 1.87  */
}
.my-profile-heading-ar{
    display: flex;
    justify-content: end;
}
.profile-cards-containers{
    width: 100%;
    border-radius: 2.625rem;
    background: rgba(255, 255, 255, 0.27);
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.19);
    padding-left: 6.56rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
}
.profile-cards-containers-ar{
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 6.56rem;
    padding-bottom: 3rem;
    align-items: end;

}
.input-fields-container{
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 1.31rem;
}
.input-fields-container-ar{
    justify-content: end;
}
.half-width-input{
    display: flex;
    gap: 0.87rem;
    width: 100%;
}
.profile-card-title{
    display: flex;
    align-items: start;
    margin-top: 2.56rem;
    margin-bottom: 0.05rem;  /* gap of the container + margin   */
    color: #000;
    font-size: 1.125rem;
    font-weight: 400;
    padding-bottom: 0.75rem;
    border-bottom: 0.7px solid #B0B0B0;
}
.profile-card-title-ar{
    justify-content: end;
}
.save-profile-button-containers{
    display: flex;
    gap: 1rem;
    margin-top: 2.32rem;  /* gap 1.12 + 2.32 = 3.44  */
}
.save-profile-button-containers-ar{
    justify-content: start;
    flex-direction: row-reverse;
}
.user-name-section{
    margin-bottom: 2.12rem;
    margin-top: 1.37rem;
}
.all-buttons-box{
    display: flex;
    justify-content: space-between;
}
.error-message-wrap{
    display: flex;
    justify-content: start;
    padding-bottom: 0.5rem;
}
.support-description{
    color: #000;
    text-align: center;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.9375rem;
    display: flex;
    justify-content: center;
    width: 100%;
    padding:0rem 5rem;

}
.support-description span{
    max-width: 78.8125rem;
    padding-top: 4.44rem;
    padding-bottom: 4.13rem;
}
.contact-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr)); /* Up to 3 cards per row */
    gap: 1.25rem;
    max-width: 95rem; 
    margin: auto;
    padding:0rem 5rem ;
    padding-bottom: 15rem;
}
.flag-address-box{
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
}
.flag-image img{
    width: 100%;
    height: 100%;
    
}
.office-address{
    display: flex;
    justify-content: start;
    text-align: left;
    align-items: center;
}
.office-address-ar{
    justify-content: end;
    text-align: right;
}

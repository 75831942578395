.not-reg-form{
    justify-content: center;
}
.arabic-input{
    flex-direction: row-reverse;
}
.back-button-box{
    position: absolute;
    left: 4rem;
    top: 4rem;
    display: flex;
    cursor: pointer;
}
.back-button-box-ar{
    left: unset;
    right: 4rem;
}
.footer-container{
    width: 100%;
    height: 9.375rem;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-inner-container{
    width: 100%;   
    /* width: 80rem;    */
    display: flex;
    justify-content: space-between;
    color: #FFF;
}

.footer-logo-box{
    width: 5.8125rem;
    height: 5.8125rem;
    border-radius: 0.81738rem;
    background: #FFF;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 4.28rem; */
}
.footer-logo-box img{
    width: 100%;
}

.footer-inner-container .heading{
    font-size: 0.9375rem;
    font-weight: 500;
}

.footer-inner-container .address{
    width: 10.875rem;
    font-size: 0.875rem;
    font-weight: 400;
}
.website{
    font-size: 0.875rem;
    font-weight: 400;
}

.footer-inner-container .contact{
    display: flex;
    align-items: flex-end;
    gap: 1.56rem;
}
.footer-inner-container-ar{
    flex-direction: row-reverse;
}
.contact-container{
    display: flex;
    text-align: left;
    gap: 4.28rem;
}
.contact-container-ar{
    text-align: right;
    flex-direction: row-reverse;
}

.terms-privacy-box{
    display: flex;
    gap: 1.75rem;
    align-items: end;
    border: none;
}
.terms-privacy-box span{
    cursor: pointer;
}


@media screen and (max-width:1200px){
    .footer-inner-container{
        /* width: 70rem; */
    }
}

@media screen and (max-width:992px){
    .footer-inner-container{
        /* width: 50rem; */
    }
    .terms-privacy-box{
        flex-direction: column;
        gap: 0.75rem;
        justify-content: flex-end;
    }
}

@media screen and (max-width:768px){
    .footer-inner-container{
        /* width: 40rem; */
        flex-direction: column;
        gap: 1rem;
    }
    .terms-privacy-box{
        flex-direction: row;
        justify-content: center;
        gap: 2.75rem;
    }
}

@media screen and (max-width:576px){
    .footer-inner-container{
        width: 100%;
    }
    .contact-container{
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
    }
    .footer-container{
        height: auto;
    }
}
.contact-card-main{
    min-height: 10rem;
    padding:2rem 1.87rem;
    display: flex;
    justify-content: start;
    gap: 1.94rem;
    align-items: start;
    border: 1px solid #CBCBCB;
    background: #F5FCFF;
}
.text-section-contact-card{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: start;
}
.icon-img-box{
    display: flex;
    align-self: flex-start;
}
.text-section-contact-card span{
    color: #000;
    font-size: 1rem;
    font-weight: 500;
}
.text-section-contact-card > *:first-child {
    font-weight: 300;
}


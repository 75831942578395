    .language-switch-container {
        position: relative;
        display: inline-block;
    }
    
    .language-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: background-color 0.3s ease;
        gap:0.5rem;
    }
    .language-icon div{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    /* .language-icon:hover {
        background-color: rgba(0, 0, 0, 0.1);
    } */
    
    .language-popup {
        position: absolute;
        top: 4.5rem;
        right: 0;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 100;
    }
    
    .language-option {
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
    
    .language-option:hover {
        background-color: #f0f0f0;
    }
    .language-arrow-icon{
        margin: -0.2rem;
    }

    @media screen and (max-width:992px){
        .language-popup{
            left: 0;
            right: auto;
        }
        .language-popup-ar{
            right: 0;
            left: auto;
        }
    }
.primary-muted-btn{
    border-radius: 0.5rem;
    border: 1px solid #B0B0B0;
    color: #666;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
    outline: none;
}
.isdelete{
    color: #F00;
}
